import React from "react"
import { graphql, navigate } from "gatsby"

/* Components */
import Layout from "../components/layout"
import Footer from "../components/footer/footer"
import StickyNav from "../components/sticky-nav/sticky-nav"
import { FaChevronLeft } from "react-icons/fa"

/* Styles */
import { returnButton } from "./betalinggeannuleerd.module.css"

const PaymentCanceled = ({ data, location }) => {
  const navigationDesks = data.navigationDesks.nodes

  const params = new URLSearchParams(location.search)
  const returnUrl = params.get("returnUrl")

  return (
    <Layout>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main" style={{ marginTop: "100px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: "250px",
            paddingBottom: "250px",
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <h1>De betaling is geannuleerd</h1>
            <p style={{ fontSize: "20px" }}>
              Informatie over de betaling kunt u vinden in uw inbox.
            </p>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                width={200}
                fill="#0046eb"
              >
                <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
              </svg>
            </div>
            {returnUrl && (
              <button
                className={returnButton}
                onClick={() => navigate(returnUrl)}
              >
                <FaChevronLeft
                  size={14}
                  color="white"
                  style={{ marginRight: "10px" }}
                />
                Terug
              </button>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}
export default PaymentCanceled

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
